@tailwind base;
@tailwind components;
html {
  scroll-behavior: smooth;
}

body{
  font-family: 'inter';
}
@tailwind utilities;


